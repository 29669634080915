<template>
  <div>
      <div class="calendar-header subtitle has-text-weight-bold">
        <h4>{{ month + ' ' + year }}</h4>
      </div>
        <div class="content">
          <div class="weeks">
            <div class="week has-text-weight-semibold" v-for="(week, index) in weeks_num" v-bind:key="index">
              {{ week }}
            </div>
          </div>
          <div class="weeks">
            <div class="week has-text-weight-semibold" v-for="(week, index) in weeksLetter" :class="getClass(week)" v-bind:key="index">
              {{ week }}
            </div>
          </div>
          <div class="calendar">
            <div class="has-text-weight-semibold" v-for="day in weekdays" v-bind:key="day">
              {{ day }}
            </div>
            <div v-for="(day, index) in days" :style="{ gridColumn: column(index) }" v-bind:key="index" :class="dayStyle(day)" >
                {{ day.format('D') }}
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: "Month",
  props: ['date'],
  data: function() {
    return {
        weekdays: ['L', 'Ma', 'Me', 'J', 'V', 'S', 'D'],
        days: [],
        month: "",
        year: "",
        weeks_num: ["N°"]
    };
  },
  mounted() {
    this.month = this.$moment(this.date).format('MMMM');
    this.year = this.$moment(this.date).format('Y');
    this.days = [...Array(this.date.daysInMonth())].map((_, i) => {
        if (!this.weeks_num.includes(this.date.clone().add(i, 'day').week()))
          this.weeks_num.push(this.date.clone().add(i, 'day').week());
        return this.date.clone().add(i, 'day')
    })
    window.scrollTo(0,0);
  },
  computed: {
    weeksFrom2020() { // Nb semaines entre la semaine 1 de 2020 (semaine B) et le début de l'année en cours d'affichage (this.date)
      var startOf2020 = this.$moment(new Date('2020-01-01')).startOf('year');
      var startOfYear = this.$moment(this.date).startOf('year');
      var n = (startOfYear.isSame(startOf2020) ? 0 : startOfYear.diff(startOf2020, 'week') + 1)
      return n
    },
    weeksLetter: function() {
      var weeks_let = ["·"];
      this.weeks_num.map((n, i) => {
        var weeksOfPreviousYear = this.$moment(this.date).clone().subtract(1, 'year').endOf('year').week();
        var nTotal = // Nb semaines entre la semaine 1 de 2020 (semaine B) et la semaine en cours d'affichage (les semaines du mois en cours sont ajoutées)
          (n == weeksOfPreviousYear && this.weeksFrom2020 && i == 1) // Ne pas ajouter le numéro de la semaine lorsque c'est la 1e semaine d'une année qui porte le numéro de la dernière semaine du mois précédant
            ? this.weeksFrom2020 : this.weeksFrom2020 + n - (this.year - 2020) % 4;
          if (Number.isInteger(nTotal))
            weeks_let.push(String.fromCharCode((nTotal % 4) + "A".charCodeAt(0)))
      })
      return weeks_let;
    },
  },
  methods: {
    column(index) {
      if (index == 0) {
        return (this.days[0].day() ? this.days[0].day() : 7)
      }
    },
    today(day) {
      return this.$moment().isSame(day, 'day')
    },
    closed(day) {
      if ((day.month() == 0 && day.format('D') == 1) // 1er Janvier
        || (day.month() == 3 && day.format('D') == 21) // 21 Avril 2025
        || (day.month() == 4 && day.format('D') == 1) // 1er Mai
        || (day.month() == 5 && day.format('D') == 9) // 9 Juin 2025
        || (day.month() == 6 && day.format('D') == 14) // 14 Juillet
        || (day.month() == 7 && day.format('D') == 15) // 15 Aout
        || (day.month() == 11 && day.format('D') == 25)) // 25 Decembre
        return true
      return false
    },
    getClass(w) {
      return w.toLowerCase();
    },
    getWeekMonth(day) {
      const firstDayOfMonth = day.clone().startOf('month');
      const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

      const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
      const weekMonth = Math.ceil((day.date() + offset) / 7);
      return weekMonth
    },
    getWeekLetter(day) {
      const startOfMonth = day.clone().startOf('month');
      const weekOfYear = day.clone().week();
      let weekOfMonth = startOfMonth.week() === 52 && weekOfYear !== 52 ? weekOfYear + 1 : weekOfYear - startOfMonth.week() + 1;
      return weekOfMonth > 0 ? this.weeksLetter[weekOfMonth].toLowerCase() : this.weeksLetter[this.weeksLetter.length - 1].toLowerCase();
    },
    countDown(day) {
      return (day.day() == 4)
    },
    dayStyle(day) {
      return { closed: this.closed(day), today: this.today(day), countDown: this.countDown(day), [this.getWeekLetter(day)]: this.countDown(day) }
    },
  }
};
</script>

<style lang="css" scoped>

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .modal {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.calendar{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  max-width: 1024px;
  width: 78%;
}

.calendar > *{
  align-items: center;
  display: flex;
  justify-content: center;
}

.calendar > *::before{
  content: "";
  display: inline-block;
  height: 0;
  padding-bottom: 100%;
  width: 1px;
}

.calendar > *.today{
  border: 2px solid #ff4200;
  border-radius: 100%;
  margin: 5px;
}

.calendar-header {
  text-align: center;
  background-color: #737479;
  color: white;
  border-radius: 20px;
  padding: 5px;
  text-transform: capitalize;
  width: 100%;
}

.weeks {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 1024px;
  width: 11%;
  text-align: center;
}

.week {
  flex: 1;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.month {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: row;
}

.a, .b, .c, .d {
  color: black;
}

.a, .b, .c, .d {
  border-radius: 100%;
}

.a {
  background-color: #FBECB8;
}
.b {
  background-color: #b9e2f1;
}
.c {
  background-color: #ffb3a3;
}
.d {
  background-color: #C3DEBE;
}

.calendar > *.countDown {
  color: black;
  border-radius: 100%;
  margin: 5px;
}

.calendar > *.closed {
  border-radius: 100%;
  margin: 5px;
  background:
    linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 48%,#737479 49%,#737479 50%,rgba(0,0,0,0) 51%,rgba(0,0,0,0) 100%),
    linear-gradient(135deg, #f2f6fa 0%,#f2f6fa 48%,#737479 49%,#737479 50%,#f2f6fa 51%,#f2f6fa 100%);
}
</style>
